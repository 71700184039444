<template>
  <div class="col-12 col-lg-8 c g">
    <br><br><br>
    <div class="col-12 text-center">
        <img :src="require('@/assets/images/banner.png')" style="width: 300px">
        <h3>masrufi.brmja.com</h3>
        <br>
    </div>
    <div class="card card-body">
        <div class="col-12" v-if="error">
            <div class="alert alert-danger text-center g">
                <h4>
                    {{ error }}
                </h4>
            </div>
        </div>
        <div class="col-12 table-responsive">
            <table class="table table-striped table-bordered table-hover">
                <thead>
                    <th>
                        الطالب
                    </th>
                    <th>
                        المبلغ
                    </th>
                    <th>
                        التاريخ
                    </th>
                </thead>
                <tbody>
                    <tr v-for="student in students" :key="student._id">
                        <td>
                            <h5>
                                {{ student.name }}
                            </h5>
                        </td>
                        <td>
                            <h5>{{ student.amount }}
                            ريال</h5>
                        </td>
                        <td>
                            {{ student.date.replace(":" + student.date.split(":")[student.date.split(":").length - 1], "") }}
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            students: [],
            jwt: JSON.parse(localStorage.getItem("user")).jwt,
            error: "",
            studentsnames: {}
        }
    },
    created(){
        var g = this;
            $.post(api + '/user/students/list', {
                jwt: this.jwt,
                q: g.q
            }).then(function(r){
                if(r.status != 100){
                    alert(r.response)
                }else{
                    r.response.forEach(function(a){
                        g.studentsnames[a.number] = a.name
                    })
                    g.getStudents()
                }
            }).fail(function(){
                alert("حدث مشكلة في الاتصال")
            })
    },
    methods: {
        getStudents(){
            var g = this;
            this.loading = true
            this.error = ""
            $.post(api + '/user/students/seller', {
                jwt: this.jwt
            }).then(function(r){
                g.loading = false
                if(r.status != 100){
                    g.error = r.response
                }else{
                    var arr = []
                    r.response.forEach(function(a){
                        a.name = g.studentsnames[a?.number]
                        arr.push(a)
                    })
                    g.students = arr;
                }
                setTimeout(() => {
                    g.getStudents()
                }, 5000);
            }).fail(function(){
                g.loading = false
                g.error = "حدث مشكلة في الاتصال"
                setTimeout(() => {
                    g.getStudents()
                }, 5000);
            })
        },
    }
}
</script>

<style>

</style>